<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: Loader.vue
Description: This file is base loader component extends the element ui load components
-->
<template>
  <div class="loading-layout" v-loading="true"></div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.loading-layout {
  @include responsiveProperty(min-height, 79vh, 82vh, 87vh);
  width: 100%;
  padding: 0px;
  margin: 0px;
  background-color: white;
  text-align: center;
}
.el-loading-mask {
  border-radius: 10px;
  // border: 1px solid #eeffef;
}
</style>
